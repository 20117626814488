import auth from "@/auth/msal-authprovider.js";
export default {
  name: "authMixin",

  data: () => ({}),
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    isAdmin() {
      return this.isInRole("ADMIN");
    },
    isGsc() {
      return this.isInRole("GSC");
    },
    isStudyOwner() {
      return this.isAdmin || this.hasAnyStudyRoles("OWNER");
    },
    canEditStudy() {
      return this.isAdmin || this.isGsc || this.hasAnyStudyRoles("OWNER", "EDITOR");
    },
    canViewStudy() {
      return this.isAdmin || this.hasAnyStudyRoles("VIEWER", "OWNER", "EDITOR");
    },
    canCreateStudy() {
      return this.isAdmin || this.isInRole("STUDY_CREATOR");
    },
    canCreateProject() {
      return this.isAdmin || this.isInRole("PROJECT_CREATOR");
    },
    canEditProject() {
      return this.isAdmin || this.hasAnyProjectRoles("OWNER", "EDITOR");
    },
    canViewProject() {
      return this.isAdmin || this.hasAnyProjectRoles("VIEWER", "OWNER", "EDITOR");
    },
    canViewImageQuestions() {
      return this.isAdmin || this.isGsc || this.hasAnyRoles("IMAGE_QUESTION_VIEWER", "IMAGE_QUESTION_EDITOR");
    },
    canEditImageQuestions() {
      return this.isAdmin || this.isGsc || this.hasAnyRoles("IMAGE_QUESTION_EDITOR");
    },
    canViewAssetCollections() {
      return this.isAdmin || this.isGsc || this.hasAnyRoles("ASSET_COLLECTION_VIEWER", "ASSET_COLLECTION_EDITOR");
    },
    canEditAssetCollections() {
      return this.isAdmin || this.isGsc || this.hasAnyRoles("ASSET_COLLECTION_EDITOR");
    },
    authedUser() {
      return auth.user;
    },
    userInitial() {
      return this.userName?.substring(0, 1)?.toUpperCase();
    },
    userFullName() {
      return auth?.getActiveAccount()?.name;
    },
    userName() {
      return auth?.getActiveAccount()?.username;
    }
  },
  methods: {
    isInRole(requiredRole) {
      return this.$store.state.user?.roles?.includes(requiredRole) === true;
    },
    hasAnyRoles() {
      var args = Array.prototype.slice.call(arguments);
      return this.$store.state.user?.roles?.some(r => args.includes(r)) === true;
    },
    hasAllRoles() {
      var args = Array.prototype.slice.call(arguments);
      return args.every(this.isInRole);
    },
    isInStudyRole(requiredRole) {
      return this.$store.state.study?.currentUserStudyRoles?.includes(requiredRole) === true;
    },
    hasAnyStudyRoles() {
      var args = Array.prototype.slice.call(arguments);
      return this.$store.state.study?.currentUserStudyRoles?.some(r => args.includes(r)) === true;
    },
    hasAllStudyRoles() {
      var args = Array.prototype.slice.call(arguments);
      return args.every(this.isInStudyRole);
    },
    hasAnyProjectRoles() {
      var args = Array.prototype.slice.call(arguments);
      return this.$store.state.project?.currentUserProjectRoles?.some(r => args.includes(r)) === true;
    }
  }
};
